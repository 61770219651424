.MrWhiteModal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.MrWhiteModal_content {
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.MrWhiteModal_content input {
  height: 50px;
  width: 250px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  margin: 25px 0;
  box-shadow: none;
  border: 1px solid #ccc;
  outline: none;
}

.MrWhiteModal_content input:focus {
  border-color: #333;
}
