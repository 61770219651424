.Button {
  background-color: #ff8a80;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  padding: 0px 50px;
  border-radius: 25px;
  height: 50px;
  line-height: 50px;
  box-shadow: inset 0px -4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.Button:hover {
  background-color: #f96666;
  box-shadow: inset 0px -2px 0 rgba(0, 0, 0, 0.2);
}

.Button[disabled] {
  opacity: 0.5;
  pointer-events: none;
  cursor: disabled;
}
