.Avatar {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.Avatar_picture {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.Avatar.is-small .Avatar_picture {
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-right: 5px;
  border: 2px solid #fff;
}

.Avatar.is-small div.Avatar_picture {
  font-size: 0;
}

div.Avatar_picture {
  color: #fff;
  line-height: 40px;
  text-align: center;
  background-color: #000;
}
