.Home {
  height: 100%;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home_title {
  font-size: 40px;
  text-transform: uppercase;
}
