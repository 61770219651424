.Card {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 15px 25px;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
}

.Card.is-votable {
  cursor: pointer;
}

.Card.is-disabled {
  opacity: 0.5;
  background-color: #eee;
}

.Card_Score {
  background-color: #eee;
  padding: 0px 10px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 10px;
  margin-left: 15px;
}

.Card.is-disabled ..Card_Score {
  background-color: #bbb;
}

.Card.is-votable:hover,
.voted-against {
  background-color: #ffffcc;
}

.Card_Role {
  display: block;
  font-style: italic;
  margin-left: 15px;
}

.Votes {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Votes_Number {
  margin-right: 25px;
  display: flex;
}

.Votes_Number > * {
  margin-right: -10px;
}

.Voted {
  width: 40px;
  height: 40px;
  opacity: 0;
}

.Card.is-votable:hover .Voted,
.Card.voted-against .Voted {
  opacity: 1;
}
