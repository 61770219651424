.Server {
  display: flex;
  height: 100%;
}

.Server_main {
  overflow: scroll;
  flex: 1 1 0;
}

.Server_sidebar {
  width: 300px;
}

.Server_game {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.Server_loading {
  margin: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Server_loading span {
  opacity: 0.5;
  margin-top: 15px;
}

.Server_footer {
  display: flex;
  color: #fff;
  font-weight: bold;
  padding: 0 25px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Server_word {
  font-weight: 400;
}

.Server_word span {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 40px;
}
